import * as yup from 'yup'

import labels from './label'

const getLabel = (path) => path === "this" ? "Энэ" : labels[path] ? labels[path] : "Энэ"

const TYPE_MGL = {
    "number": "тоон утга"
}

yup.setLocale({
    mixed: {
        required: ({ path }) =>
        {
            return  `${getLabel(path)} талбарыг бөглөнө үү.`
        },
        notType: ({ path, type, value }) =>
        {
            return  `${getLabel(path)} талбарын төрөл нь ${TYPE_MGL?.[type] ?? type} байна тиймээс ${TYPE_MGL?.[type] ?? type} хэрэглэнэ үү.`
        },
        oneOf: ({ path, values }) =>
        {
            return  `${getLabel(path)} талбар нь '${values.toString()} 'уг утгуудаас сонгоно`
        }
    },
    number: {
        positive: ({ path }) =>
        {
            return`${getLabel(path)} талбар нь заавал эерэг утга байх ёстой.`
        },
        min: ({ min, path }) => ( `${getLabel(path)} талбар нь хамгийн багадаа '${min}' байна` ),
        max: ({ max, path }) => ( `${getLabel(path)} талбар нь хамгийн ихдээ '${max}' байна` ),
    },
    boolean: {
        isValue: ({ path }) =>
        {
            return  `${getLabel(path)} талбар нь бла бала`
        }
    },
    string: {
        min: ({ min, path }) => ( `${getLabel(path)} талбар нь хамгийн багадаа '${min}' байна`),
        max: ({ max, path }) => ( `${getLabel(path)} талбар нь хамгийн ихдээ ${max} байна` ),
        length: ({ length, path }) => ( `${getLabel(path)} талбарын утгын урт нь ${length} байх ёстой.` ),
        email: ({ path, value }) =>
        {
            return  `${getLabel(path)} талбарт и-мэйл бөглөнө үү.`
        },
    },
});

export default yup

export const validates = {
	string: yup.string().required().trim(),
	geom: yup.string().required("Геом сонгоно уу").trim(),
	selectMulti: yup
		.array()
		.required()
		.test("len", "1-ээс илүү утга сонгох ёстой", value => value?.length >= 1),
	positiveNumber: yup.number().required().positive(),
	select: yup
        .number()
		.required()
		.test("len", "Сонгоно уу", value => value?.length !== 0),
	file: yup.mixed().test("fileRequired", "Файл оруулна уу", value => {
		if (value === undefined) return false
		if (value?.length === 0) return false
		return true
	}),
	acceptsZero: yup.number().required().moreThan(-1, "Эерэг утга оруулна уу"),
	percent: yup.number().required().min(0).max(100),
	selectBoolean: yup
		.bool()
		.required()
		.test("len", "Сонгоно уу", value => typeof value === "boolean"),
	date: yup.date().required(),
	email: yup.string().email().required().max(80),
	register: yup
		.string()
        .length(10)
        .matches(/[a-яА-Я]{2}[0-9]{8}/, `ТБ98020531 хэлбэрээр регистрийн дугаараа оруулна уу`)
		.required(),
    companyOrRegister: yup
        .string()
        .matches(/^([a-яА-Я]{2}[0-9]{8}|[0-9]{7})$/, `ТБ98020531 хэлбэрээр регистрийн дугаараа оруулна уу. Байгуулгийн id бол 7 урттай байна.`)
        .required(),
}

export const firstName = {
    firstName: yup.string().required().max(80)
}


export const lastName = {
    lastName: yup.string().required().max(80)
}

export const phoneNumber = {
    phoneNumber: validates.positiveNumber.test('len', 'Утасны дугаараа оруулна уу', val =>
    {
        let value = '' + val
        return value.length === 8
    }
    ),
}

export const newPassword = {
    newPassword: yup.string().required().matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
        `Хамгийн багадаа 8 тэмдэгт (том үсэг 1ш, жижиг үсэг 1ш, тоо 1ш) орсон байх шаардлагатай`).max(80),
}

export const confirmPassword = {
    confirmPassword: yup.string().oneOf([yup.ref("newPassword"), null], "Нууц үгүүд ижил биш байна").required().max(80),
}

export const username = {
    username: yup.string().required().max(80)
}

export const checkBox = {
    checkBox: yup.boolean().oneOf([true])
}

export const email = {
    email: yup.string().email().required().max(80),
}

export const companyId = {
    companyId: yup.string().required().max(80),
}

export const company = {
    company: yup.string().required().max(80),
}

export const register = {
    register: validates.positiveNumber
}

export const password = {
    password: yup.string().required().max(80),
}

export const price = {
    price: validates.positiveNumber
}

export const quantity = {
    quantity: yup.number().required().min(0).integer().positive()
}

export const withDraw = {
    quantity: yup.number().required().min(0).integer()
}

export const kindType = {
    kindType: yup.string().required()
}

export const kind = {
    kind: yup.string().required()
}

export const name = {
    name: yup.string().required().max(30)
}

export const code = {
    code: yup.string().required()
}
export const hasAccess = {
    hasAccess: yup.bool().default(true)
}

export const description = {
    description: yup.string().required().max(1000)
}
export const location = {
    location: yup.string().required().max(500)
}
export const total = {
    family: yup.number().positive(),
    square: yup.number().positive(),
    perSquare: yup.number().positive(),
}

export const reason = {
    reason: yup.string().required()
}

export const result = {
    result: yup.number().required().moreThan(-1, '0-100 хооронд утга оруулна уу').lessThan(101, '0-100 хооронд утга оруулна уу')
}

export const amount = {
    amount: validates.positiveNumber
}

export const budget_amount = {
    budget_amount: validates.positiveNumber
}

export const units = {
    units: validates.string
}

export const end_date = {
    end_date:  yup.string().required(),
}

export const hutulbur = {
    hutulbur:  yup.string().required(),
}

export const zoriltotUser = {
    zoriltotUser:  yup.array().required(),
}

export const total_ail = {
    total_ail: validates.positiveNumber.integer()
}

export const bairshil_point = {
    urtrag: yup.string().matches(
        /^\d+(\.\d{0,13})?$/,
        `Хамгийн багадаа таслалаас хойш 13 орон байна.`
    ).test('point_validation', 'Заавал уртраг өргөрөг 2-уулаа утгатай байна.', val =>
    {
        var orgorog = document.getElementById('orgorog').value
        if (orgorog) return val
        return true
    }),
    orgorog: yup.string().matches(
        /^\d+(\.\d{0,13})?$/,
        `Хамгийн багадаа таслалаас хойш 13 орон байна.`
    ).test('point_validation', 'Заавал уртраг өргөрөг 2-уулаа утгатай байна.', val =>
    {
        var urtrag = document.getElementById('urtrag').value
        if (urtrag) return val
        return true
    }),
}

export const createOrderValidate = yup.object().shape({
    ...kind,
    ...price,
    ...quantity,
    ...kindType,
});

export const testValidate = yup.object().shape({
    ...username,
    ...email,
    ...price,
});

export const currencyValidate = yup.object().shape({
    ...name,
    ...code,
});

export const signinValidate = yup.object().shape({
    ...email,
    ...password,
});

export const registerValidate = yup.object().shape({
    ...email,
    company: validates.select,
})

export const companyRegisterValidate = yup.object().shape({
    ...register,
    "regnum": validates.string,
    "company": validates.string,
    "prop_type": validates.select,
    "director": validates.string,
    "email": validates.string,
})

export const companyRegisterFormValidate = yup.object().shape({
    regnum: validates.number,
    company: validates.string,
    director: validates.string,
    prop_type: validates.string,
    license: validates.string,
    sub_license: validates.string,
    email: validates.email,
})

export const orgAdminValidate = yup.object().shape({
    ...companyId,
    ...email,
})

export const orgFromFinancialAdminValidate = yup.object().shape({
    company: validates.string,
    ...email,
})

export const songonValidate = yup.object().shape({
    name: validates.string,
    end_date: validates.date,
    zoriltotUser: validates.selectMulti,

})

export const newPasswordValidate = yup.object().shape({
    ...newPassword,
    ...confirmPassword,
});

export const getMailValidate = yup.object().shape({
    ...email,
});

export const signupValidate = yup.object().shape({
    ...lastName,
    ...firstName,
    ...phoneNumber,
    ...email,
    ...newPassword,
    ...confirmPassword,
    ...checkBox
});



export const symbolValidate = (negjErhiinVne, balanceMNT) => yup.object().shape({
    quantity: validates.positiveNumber.integer('Та бүхэл тоо оруулна уу').test('len', 'Таны дансны үлдэгдэл хүрэлцэхгүй байна', value => balanceMNT > negjErhiinVne * value || balanceMNT === negjErhiinVne * value),
})

export const singleQuantityValidate = yup.object().shape({
    singleQuantity: yup.number().positive().integer('Та бүхэл тоо оруулна уу')
});

export const withDrawValidate = (minLimit, maxLimit) => yup.object().shape({
    withDraw: yup
                .number()
                .required()
                .positive()
                .integer('Та бүхэл тоо оруулна уу')
                .max(maxLimit, 'Таны дансны үлдэгдэл хүрэлцэхгүй байна')
                .min(minLimit, 'Таны оруулсан утга зарлагын доод дүнгээс бага байна'),
})

export const reasonValidate = yup.object().shape({
    ...reason
})

export const testValidate2 = yup.object().shape({
    TestEmail: yup.string().required().max(80),
    TestPassword: yup.string().required().max(80),

    // Тухайн input нь хосон байж болох бол .notRequired().nullable(true).transform((v, o) => o === '' ? null : v) гэсэн зүйлсийг нэмж бичнэ
    TestNumber: validates.positiveNumber,

    // Datepicker 1 ийг л сонгох үед
    testDatePicker: validates.date,

    // Datetimepicker Range үед энэ validate ийг ажиллуулна TODO: сайжруулалт хэрэгтэй
    // testDatePickerRange: yup
    //     .array()
    //     .required()
    //     .test({
    //         message: "Дуусах хугацаа сонгоно уу",
    //         test: (arr) => arr?.[1] !== null,
    //     }),
    // Multi select ийг validate хийихэд value?.length >= ? энд хэдийг сонгож болохоо оруулах алдааны msg ээ бич
    TestSelectMulti: yup
        .array()
        .required()
        .test("len", "test select multi deer ta 1 ees ilvv g songoh yostoi", (value) => value?.length >= 2),

    // Select ийг validate хийхэд
    TestSelect: yup
        .string()
        .required()
        .test("len", "test select deer aldaa garlaa", (value) => value?.length !== 0),

    // Зураг upload хийх
    imageUpload: yup
        .mixed()
        .test("fileRequired", "Файл оруулна уу", (value) => {
            if (!value || value.length === 0) return false;
            return true
        })
        .test("fileSize", "Файлын хэмжээ хэтэрхий том байна", (value) => {
            if (!value || value.length === 0) return false;
            return value[0].size <= 100000000;
        }),

    // Radio button
    // TestRadio: yup
    //     .string().oneOf(["true","false"]).required(),
});

/** gov validate */
export const govValidate = yup.object().shape({
    name: yup.string().required().max(150).trim()
})

/** program validate */
export const programValidate = yup.object().shape({
	name: validates.string,
	budget_type: validates.select,
	...budget_amount,
	...end_date,
	main_subject: validates.select,
	contrib_subject: validates.selectMulti,
})

/** agenda validate */
export const agendaValidate = yup.object().shape({
    agenda: validates.string,
    ...amount,
})

export const subAgendaValidate = yup.object().shape({
    subagenda: validates.string,
    ...amount,
    ...units,
    basestatus: validates.string,
    // currentstatus: validates.string,
    futurestatus: validates.string,
    budget_type: validates.selectMulti,
    end_date: validates.date,
})

export const licenseTypeValidate = yup.object().shape({
    topic: validates.string,
    index: validates.string,
    licensed_by: validates.string,
    license_year: validates.positiveNumber
})

export const salesValidate = yup.object().shape({
    manager: validates.string,
    contact: validates.string,
    ...email,
    website: yup.string().matches(/(?: ?\G|^https:|http:)/, 'https://www.google.com/ хэлбэрээр бичнэ үү').required('https://www.google.com/ хэлбэрээр бичнэ үү').trim(),
    facebook: yup.string().matches(/(?: ?\G|^https:|http:)/, 'https://www.google.com/ хэлбэрээр бичнэ үү').required('https://www.google.com/ хэлбэрээр бичнэ үү').trim(),
    instagram: yup.string().matches(/(?: ?\G|^https:|http:)/, 'https://www.google.com/ хэлбэрээр бичнэ үү').required('https://www.google.com/ хэлбэрээр бичнэ үү').trim(),
    twitter: yup.string().matches(/(?: ?\G|^https:|http:)/, 'https://www.google.com/ хэлбэрээр бичнэ үү').required('https://www.google.com/ хэлбэрээр бичнэ үү').trim(),
})


export const petitionerValidate = yup.object().shape({
    condition: validates.string,
    work_place: validates.string,
    work_position: validates.string,
    org_type: validates.select,
    until_work_year: validates.string,
    org_work_year: validates.string,
    income_exp_info: validates.string,
})

export const advantageValidate = yup.object().shape({
    name: validates.string,
})

export const doortypeValidate = yup.object().shape({
    door_type: validates.string,
})

export const windowtypeValidate = yup.object().shape({
    window_type: validates.string,
})

export const parkingtypeValidate = yup.object().shape({
    name: validates.string,
})

export const apartLocationValidate = yup.object().shape({
    from_school: validates.string.max(100),
    from_super_market: validates.string.max(100),
    from_kinder_garden: validates.string.max(100),
    from_bus_station: validates.string.max(100),
})

export const projectValidate = (isCreate, isFromMap, isOld) =>
{
    return yup.object().shape({
        project_name: yup.string().required().trim(),
        approval: yup.string().required().trim(),
        description: yup.string().required().trim(),
        et_file: null,
        general_plan: null,
        unit4: yup.string().required().trim(),
        price_mkv: validates.positiveNumber,
        // total_service_area: validates.positiveNumber,
        approval_date: validates.date,
        use_date: validates.date,
        aorson_ognoo: isOld ? validates.date : null,
        current_use: yup
            .number()
            .required()
            .test("len", "Сонгоно уу", value => value?.length !== 0),
        eskiz_info: isCreate ? validates.file : null,
        sales_manager: yup
            .number()
            .required()
            .test("len", "Сонгоно уу", value => value?.length !== 0),
        budget_type: validates.selectMulti,
        // ...total_ail,
        property_type: validates.selectMulti,
        parking_type: validates.selectMulti,
        construction: validates.selectMulti,
        tonoglol: yup
            .number()
            .required()
            .test("len", "Сонгоно уу", value => value?.length !== 0),
        is_end: isFromMap ? validates.selectBoolean : null,
        omchiin_helber: yup
            .number()
            .required()
            .test("len", "Сонгоно уу", value => value?.length !== 0),

        barilgiin_torol: yup
            .number()
            .required()
            .test("len", "Сонгоно уу", value => value?.length !== 0),
        barilgiin_zoriulalt: yup
            .number()
            .required()
            .test("len", "Сонгоно уу", value => value?.length !== 0),

        // 'м': yup.string().required().trim(),
        // 'км': yup.string().required().trim(),
        // 'у/м': yup.string().required().trim(),
        // 'кг': yup.string().required().trim(),
        // 'тн': yup.string().required().trim(),
        // 'м3': yup.string().required().trim(),
        // 'м2': yup.string().required().trim(),
        // 'га': yup.string().required().trim(),
        // 'Зоорьтой давхар': yup.string().required().trim(),
        // 'кВА': yup.string().required().trim(),
        // 'кВт': yup.string().required().trim(),
        // 'мВА': yup.string().required().trim(),
        // 'төгрөг': yup.string().required().trim(),
        // 'нэгжгүй': yup.string().required().trim(),
        // 'авто машин': yup.string().required().trim(),
        // 'хоолойн голч': yup.string().required().trim(),
        // 'ор': yup.string().required().trim(),
        // 'суудал': yup.string().required().trim(),
        // 'хүүхэд': yup.string().required().trim(),
        // 'айлын тоо': yup.string().required().trim(),
        // 'машины тоо': yup.string().required().trim(),
        // '0+1': yup.string().required().trim(),
        // '2+0': yup.string().required().trim(),
        // 'Шөрмөс': yup.string().required().trim(),
        // 'Хос': yup.string().required().trim(),
        // 'ширхэг': yup.string().required().trim(),
        // 'Вт': yup.string().required().trim(),
        // 'мян.м3': yup.string().required().trim(),
        // 'Хоолойн голч /ф32-150 мм/': yup.string().required().trim(),
        // 'Хоолойн голч/ф100-300 мм/': yup.string().required().trim(),
        // 'ор/суудал': yup.string().required().trim(),
        // 'чиглэлийн': yup.string().required().trim(),
        // 'эргэдэг': yup.string().required().trim(),
        // 'хүн': yup.string().required().trim(),
        // 'СИП': yup.string().required().trim(),
        // '10-с дээш Гкал/цаг': yup.string().required().trim(),
        // 'кВ': yup.string().required().trim(),
        // 'м3/цаг': yup.string().required().trim(),
        // 'Гкал/цаг': yup.string().required().trim(),
        // 'дугаар илрүүлэгчтэй': yup.string().required().trim(),
        // 'тусгай зориулалтын': yup.string().required().trim(),
        // 'мм': yup.string().required().trim(),
        // 'Хоолойн голч /ф200-450 мм/': yup.string().required().trim(),
        // 'Хоолойн голч /ф500-1400 мм/': yup.string().required().trim(),
        // 'Хоолойн голч /ф300 мм-с дээш/': yup.string().required().trim(),
        // 'Шугамын урт /м/': yup.string().required().trim(),
        // '0.4 кВ КШ': yup.string().required().trim(),
        // '0.4-10 кВ ЦДАШ': yup.string().required().trim(),
        // '6-35 кВ ЦДКШ': yup.string().required().trim(),
        // 'хүн ам': yup.string().required().trim(),
        // 'хоргын тоо': yup.string().required().trim(),
        // 'Зоорьгүй давхар': yup.string().required().trim(),
        // '0.4, 6, 10, 15, 35 кВ': yup.string().required().trim(),
        // 'цэг': yup.string().required().trim(),
        // '3 хүртэл Гкал/цаг': yup.string().required().trim(),
        // '3-10 хүртэл Гкал/цаг': yup.string().required().trim(),
        // 'ш': yup.string().required().trim(),
    })
};

export const residenceValidate = yup.object().shape({
    plan_img: validates.file,
    total_mkv: validates.positiveNumber,
    total_room: validates.positiveNumber,
    apartment: validates.selectMulti,
});

export const apartmentValidate = (isTosk) =>
{
    return yup.object().shape(
        {
            name: validates.string,
            butets_type: validates.select,
            real_photo: validates.file,
            advantage: validates.selectMulti,
            service_floor: validates.acceptsZero,
            total_floor: validates.positiveNumber,
            ...total_ail,
            zorilgot_buleg: isTosk ? validates.selectMulti : null,
            total_service_area: validates.acceptsZero,
            total_under_floor: validates.acceptsZero,
            ortsnii_too: validates.acceptsZero,
            window_type: validates.selectMulti,
            door_type: validates.selectMulti,
            use_type: validates.selectMulti,
            lift_ype: validates.selectMulti,
            dohiolol: validates.selectMulti,
            zereglel: validates.select,
        }
    )
}

export const buildingValidate = yup.object().shape({
	door_number: validates.string,
	input_number: validates.string,
	floor: validates.string,
	residence: validates.select,
    state: validates.select,
	is_rent: validates.selectBoolean,
})

export const manyBuildingValidate = yup.object().shape({

})

export const buildingImageValidate = yup.object().shape({
    desciption: validates.string,
    real_photo: validates.file,
})

export const buySellValidate2 = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.companyOrRegister,
    building: validates.select,
    ul_hudluh_hurungiin_turul: validates.select,
    uil_ajillagaanii_turul: validates.select,
    price: validates.positiveNumber,
    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
})

export const buySellValidate = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.companyOrRegister,
    project: validates.select,
    apartment: validates.select,
    building: validates.select,
    ul_hudluh_hurungiin_turul: validates.select,
    uil_ajillagaanii_turul: validates.select,
    price: validates.positiveNumber,
    ognoo: validates.date,
    dugaar: validates.string,
    // geree_gerchilgee_dugaar: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
})

export const OldbuyOldSellValidate = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.companyOrRegister,
    ul_hudluh_hurungiin_turul: validates.select,
    uil_ajillagaanii_turul: validates.select,
    mkv: validates.positiveNumber,
    price: validates.positiveNumber,
    ognoo: validates.date,
    dugaar: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
})

export const OldbuySellValidate = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.register,
    ul_hudluh_hurungiin_turul: validates.select,
    uil_ajillagaanii_turul: validates.select,
    mkv: validates.positiveNumber,
    price: validates.positiveNumber,
    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
    full_name: validates.string,
    unit1: validates.select,
    unit2: validates.select,
    unit3: validates.select,

    geoid: validates.geom,
})

export const OldbuySellValidate2 = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.companyOrRegister,
    ul_hudluh_hurungiin_turul: validates.select,
    uil_ajillagaanii_turul: validates.select,
    mkv: validates.positiveNumber,
    price: validates.positiveNumber,
    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
    full_name: validates.string,
})

export const tureesValidate2 = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.companyOrRegister,
    building: validates.select,
    ul_hudluh_hurungiin_turul: validates.select,
    uil_ajillagaanii_turul: validates.select,
    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
    per_month_price: validates.positiveNumber,
    gereenii_hugatsaa: validates.positiveNumber,
    total_price: validates.positiveNumber,
})

export const tureesValidate = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.companyOrRegister,
    project: validates.select,
    apartment: validates.select,
    building: validates.select,
    ul_hudluh_hurungiin_turul: validates.select,
    uil_ajillagaanii_turul: validates.select,
    ognoo: validates.date,
    dugaar: validates.string,
    // geree_gerchilgee_dugaar: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
    per_month_price: validates.positiveNumber,
    gereenii_hugatsaa: validates.positiveNumber,
    total_price: validates.positiveNumber,
})

export const OldTureesValidate = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.companyOrRegister,
    ul_hudluh_hurungiin_turul: validates.select,
    uil_ajillagaanii_turul: validates.select,
    mkv: validates.positiveNumber,
    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
    per_month_price: validates.positiveNumber,
    gereenii_hugatsaa: validates.positiveNumber,
    total_price: validates.positiveNumber,
    full_name: validates.string,
    unit1: validates.select,
    unit2: validates.select,
    unit3: validates.select,
    geoid: validates.geom,
})

export const OldTureesValidate2 = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.companyOrRegister,
    ul_hudluh_hurungiin_turul: validates.select,
    uil_ajillagaanii_turul: validates.select,
    mkv: validates.positiveNumber,
    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
    per_month_price: validates.positiveNumber,
    gereenii_hugatsaa: validates.positiveNumber,
    total_price: validates.positiveNumber,
    full_name: validates.string,
})

export const menegmentValidate = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.register,
    irget_huuliin_etgeedin_haryalal: validates.string,


    project: validates.select,
    apartment: validates.select,
    building: validates.select,

    ul_hudluh_hurungiin_turul: validates.select,
    uilchilgee_description: validates.string,

    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
    niit_dun: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
})

export const menegmentValidate2 = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.register,
    irget_huuliin_etgeedin_haryalal: validates.string,

    building: validates.select,

    ul_hudluh_hurungiin_turul: validates.select,
    uilchilgee_description: validates.string,

    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
    niit_dun: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
})

export const oldMenegmentValidate = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.register,
    irget_huuliin_etgeedin_haryalal: validates.string,

    ul_hudluh_hurungiin_turul: validates.select,
    uilchilgee_description: validates.string,

    mkv: validates.positiveNumber,
    full_name: validates.string,
    unit1: validates.select,
    unit2: validates.select,
    unit3: validates.select,

    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
    niit_dun: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,

    geoid: validates.geom,
})

export const oldMenegmentValidate2 = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.register,
    irget_huuliin_etgeedin_haryalal: validates.string,

    ul_hudluh_hurungiin_turul: validates.select,
    uilchilgee_description: validates.string,

    mkv: validates.positiveNumber,
    full_name: validates.string,

    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
    niit_dun: validates.string,
    // shimtgel_dun: validates.acceptsZero,
    huwi: validates.acceptsZero,
})

export const busadValidate = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.register,
    irget_huuliin_etgeedin_haryalal: validates.string,

    uilchilgee_total_price: validates.positiveNumber,
    uilchilgee_description: validates.string,
})

export const zohihGuilgeeValidate = yup.object().shape({
    irgen_name: validates.string,
    register: validates.register,

    guilgee_dun: validates.positiveNumber,
    guilgee_turul: validates.select,
    guilgee_ognoo: validates.date,
    SMA_medegdsen_ognoo: validates.date,

    hariltsagchdiin_too: validates.acceptsZero,
    NUBAZ_horig_tulgsan_hariltsagchdiin_too: validates.acceptsZero,
})

export const zassanValidateNew = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.register,

    project: validates.select,
    apartment: validates.select,
    building: validates.select,

    ul_hudluh_hurungiin_turul: validates.select,

    hudaldaj_awsan_une: validates.acceptsZero,
    zaswar_zardal: validates.acceptsZero,
    zarsan_une: validates.acceptsZero,
    olz_garz: validates.acceptsZero,

    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
})

export const zassanValidateNewFromMap = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.register,

    building: validates.select,

    ul_hudluh_hurungiin_turul: validates.select,

    hudaldaj_awsan_une: validates.acceptsZero,
    zaswar_zardal: validates.acceptsZero,
    zarsan_une: validates.acceptsZero,
    olz_garz: validates.acceptsZero,

    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
})

export const zassanValidateOld = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.register,

    unit1: validates.select,
    unit2: validates.select,
    unit3: validates.select,
    full_name: validates.string,

    ul_hudluh_hurungiin_turul: validates.select,

    hudaldaj_awsan_une: validates.acceptsZero,
    zaswar_zardal: validates.acceptsZero,
    zarsan_une: validates.acceptsZero,
    olz_garz: validates.acceptsZero,

    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,

    geoid: validates.geom,
})

export const zassanValidateOldFromMap = yup.object().shape({
    irgen_huuliin_etgeedin_ner: validates.string,
    register: validates.register,

    full_name: validates.string,

    ul_hudluh_hurungiin_turul: validates.select,

    hudaldaj_awsan_une: validates.acceptsZero,
    zaswar_zardal: validates.acceptsZero,
    zarsan_une: validates.acceptsZero,
    olz_garz: validates.acceptsZero,

    ognoo: validates.date,
    dugaar: validates.string,
    geree_gerchilgee_dugaar: validates.string,
})

export const staffCreateValidate = yup.object().shape({
    email: validates.email,
    register: validates.register,
})

export const peopleTypeValidate = yup.object().shape({
    email: validates.email,
    register: validates.register,
    type: validates.select
})

export const changeMailValidate = yup.object().shape({
    mail: validates.email
})

export const roomValidate = yup.object().shape({
    room_name: validates.string,
    room_code: validates.string,
    icon: validates.string
})

export const resetPasswordValidate = yup.object().shape({
    email: validates.email
})

export const changeNames = yup.object().shape({
    first_name: validates.string,
    last_name: validates.string
})

export const newsPageValidate = yup.object().shape({
    page_name: validates.string,
    code: validates.string,
})

export const newsValidate = yup.object().shape({
    "title": validates.string,
    "image": validates.file,
    "commentary": validates.string,
    "content": validates.string,
})

export const borluulaltValidate = yup.object().shape({
    price_mkv: validates.positiveNumber,
	total_price: validates.positiveNumber,
	sales: validates.select,
	property_type: validates.select,
})

export const activityValidate = yup.object().shape({
	name: validates.string,
})

export const oldApartmentValidate = yup.object().shape({
    name: validates.string,
    butets_type: validates.select,
    real_photo: validates.file,
    has_lift: validates.selectBoolean,
    service_floor: validates.acceptsZero,
    total_floor: validates.positiveNumber,
    ...total_ail,
    total_service_area: validates.acceptsZero,
    total_under_floor: validates.acceptsZero,
    unit1: validates.select,
    unit2: validates.select,
    unit3: validates.select,
});

export const oldBuildingValidate = yup.object().shape({
	door_number: validates.string,
	input_number: validates.string,
	floor: validates.string,
	is_rent: validates.selectBoolean,
    total_mkv: validates.positiveNumber,
    total_room: validates.positiveNumber,
    full_name: validates.string
})

export const unitFormValidate = yup.object().shape({
	unit1: validates.select,
})


export const oldSongonWinnerValidateExtra = yup.object().shape({
	register: validates.register,
	zoriltot_buleg: validates.select,
	condition: validates.string,
	work_place: validates.string,
	org_type: validates.select,
	work_position: validates.string,
	until_work_year: validates.string,
	org_work_year: validates.string,
	income_exp_info: validates.string,
})

export const oldSongonWinnerValidateLess = yup.object().shape({
	register: validates.register,
	zoriltot_buleg: validates.select,
})

export const partnerValidate = yup.object().shape({
    partner: validates.select
})

export const reportOc1Validate = yup.object().shape({
    unit1: validates.select,
    unit2: validates.select,
    unit3: validates.select,
    ...bairshil_point,
})

export const materialAdminValidate = yup.object().shape({
    name: validates.string,
    email: validates.email,
})

export const materialTypeValidate = yup.object().shape({
    mattype: validates.string,
})

export const materialNameValidate = yup.object().shape({
    matname: validates.string,
})

export const materialPriceValidate = yup.object().shape({
    unit: validates.string,
    price: validates.positiveNumber,
    date: validates.date,
})

export const videoUrlValidation = yup.object().shape({
    ABOUT_PAGE_VIDEO_URL: yup.string().matches(/(?: ?\G|^https:|http:)/, 'Холбоос биш байна.').required('Холбоос оруулна уу.').trim(),
})

export const bhRegisterValidate = yup.object().shape({
    email: validates.email,
})

export const companiesCreateValidate = yup.object().shape({
    name: validates.string,
    register_number: validates.string,
    gerchilgeenii_dugaar: validates.string,
    hayg: validates.string,
    phone: validates.string,
    email: validates.email,
})

export const helperValidate = yup.object().shape({
	name: validates.string,
	target_group: validates.string,
    video: yup.mixed().test("fileRequired", "Файл оруулна уу", value => {
        if (typeof value === 'string')
        {
            return true
        }
		if (value === undefined) return false
		if (value?.length === 0) return false
        if (value[0].type !== 'video/mp4') return false
		return true
	}),
    pdf: yup.mixed().test("fileRequired", "PDF файл оруулна уу", value => {
        if (typeof value === 'string')
        {
            return true
        }
        if (value && value[0])
        {
            if (value[0].type !== 'application/pdf') return false
        }
		return true
	}),
})
