import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {getCenter} from 'ol/extent';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { OldTureesValidate, OldTureesValidate2, tureesValidate, tureesValidate2 } from 'utils/validate';
import { dataToValueLabel } from 'utils/format';
import { UIL_AJILLAGAANII_TURUL, UL_HODLOH_TURUL_TUREES, NSDI_2D_BUILDING, NSDI_CADASTER } from 'utils/consts';
import { capitalizeFirstLetter } from 'utils'
import useMapContext from 'hooks/useMapContext';
import MapChoice from '../MapChoice';
import { transform } from 'ol/proj';
import { DATA_PROJECTION, FEATURE_PROJECTION } from 'hooks/map/CMap';

const TureesAction = ({ hasTitle=true, isNew=null, setTogglerState, updateId }) => {

    const [ inputValues, setInputValues ] = useState('');
    const [ realValues, setRealValues ] = useState('')

    const [ choosedNew, setChoosedNew ] = useState(null)
    const [ estateTypes, setEstateTypes ] = useState({})
    const [ buildingsOfApart, setBuildings ] = useState([]);
    const [ projectNested, setProjectNested ] = useState([]);
    const [ apartmentNested, setApartmentNested ] = useState([])
    const [ labelType, setLabelType ] = useState(NSDI_2D_BUILDING)

    const [ units, setUnits ] = useState([])
    const [ unit2Options, setUnit2Options ] = useState([])
    const [ selectUnit1, setUnit1 ] = useState('')
    const [ selectUnit2, setUnit2 ] = useState('')

    const [ project, setProject ] = useState({});
    const [ apartment, setApartment ] = useState({});
    const [ activitys, setActivitys ] = useState([])
    const [ gerchilgees, setGerchilgees ] = useState([])
    const navigate = useNavigate();
    let { id, tableId } = useParams()

    const { fetchData, isLoading, Loader } = useLoader({ absolute: true })
    const buildingTableApi = useApi().buildingTable
    const projectNestedApi = useApi().projectNested
    const unitApi = useApi().unit
    const activityApi = useApi().activity

    const { selectedUnits, choosedFeature, setShouldClosePopup } = useMapContext()

    /** create бол true, update бол false буцаана */
    let isCreate = !hasTitle ? true : id === 'create'
    isCreate = !isCreate ? isCreate : updateId ? false : true
    id = updateId ? updateId : id

    /** Регистрээр хэрэглэгчийн датаг авах нь */
    const getUserData = async (event, { onChange, getFieldState, registerName, setValue, getValues, setError }) =>
    {
        await onChange(event)
        let turul = getValues('ul_hudluh_hurungiin_turul')
        const { invalid } = getFieldState(registerName)
        if(turul===4 || turul===5)
            turul = 2
        if (!turul)
            return
        if (!invalid && turul !== 1)
        {
            const body =
            {
                register: event.target.value,
                turul: turul
            }
            const { success, data, errors } = await fetchData(buildingTableApi.userData(body))
            if (success)
            {
                const ulTurulObj = UL_HODLOH_TURUL_TUREES.find(e => e.value === turul)
                const turulLabel = turul === 2 ? "Үл хөдлөх" : ulTurulObj.label
                const { firstname, lastname, property } = data
                const etgeediinNer = `${capitalizeFirstLetter(lastname)} ${capitalizeFirstLetter(firstname)}`
                setValue("irgen_huuliin_etgeedin_ner", etgeediinNer, { shouldValidate: true })
                setGerchilgees(property.filter(e => e.serviceType.toLowerCase() === turulLabel.toLowerCase()))
            }
            else {
                setError(registerName, { type: 'custom', message: 'Дахин шалгана уу' })
            }
        }
        else {
            setValue("irgen_huuliin_etgeedin_ner", "")
            setValue("geree_gerchilgee_dugaar", "")
            setValue("full_name", "")
            setValue("mkv", "")
            setGerchilgees([])
        }
    }

    const handleChangeGerchilgees = (selectedValue, { setSelectedOption, setValue }) =>
    {
        const gerchilgeeObj = gerchilgees.find(e => e['propertyNationRegisterNumber'] === selectedValue.value)
        setValue("mkv", gerchilgeeObj['propertySize'])
        setValue("full_name", gerchilgeeObj['fullAddress'])
    }

    const handleChangeGerchilgeesV2 = (event, { setValue }) =>
    {
        console.log('event', event, gerchilgees)
        const gerchilgeeObj = gerchilgees.find(e => e['propertyNationRegisterNumber'] === event.target.value)
        if (gerchilgeeObj)
        {
            setValue("mkv", gerchilgeeObj['propertySize'])
            setValue("full_name", gerchilgeeObj['fullAddress'])
        }
        else
        {
            setValue("mkv", '')
            setValue("full_name", '')
        }
    }

    useEffect(
        async () =>
        {
            if(!isNew)
            {
                setEstateTypes(UL_HODLOH_TURUL_TUREES.filter(
                    (element, index) =>
                    {
                        if(index === 0) return
                        return element
                    }
                ))
            }
            else
            {
                setEstateTypes([UL_HODLOH_TURUL_TUREES[0]])
            }

            if (isNew === true)
            {
                const { success, data, error } = await fetchData(buildingTableApi.buildingOfApartment(choosedFeature.feature.get("apartment_id"), tableId)).catch(err => err)
                if (success)
                {
                    setBuildings(dataToValueLabel(data, 'id', 'name'))
                }
            }
            else {
                const unitRsp = await fetchData(unitApi.get())
                const projectNestedRsp = await fetchData(projectNestedApi.get())
                if(unitRsp.success && projectNestedRsp.success)
                {
                    setProjectNested(projectNestedRsp.data)
                    setUnits(unitRsp.data)
                }
            }
            const activityRsp = await fetchData(activityApi.get())
            if (activityRsp.success)
            {
                setActivitys(dataToValueLabel(activityRsp.data, 'id', 'name'))
            }

        },
        [isNew]
    )

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data } = await fetchData(buildingTableApi['turees'].getOne(id))
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    const handleIsNew = (svalue, { setValue }) =>
    {
        const value = typeof svalue === "object" ? svalue.value : svalue
        if(value === 1)
        {
            setValue("register", "")
            setValue("irgen_huuliin_etgeedin_ner", "")
            setValue("geree_gerchilgee_dugaar", "")
            setValue("full_name", "")
            setValue("mkv", "")
            setGerchilgees([])
            setChoosedNew(true)
        }
        else {
            setValue("register", "")
            setValue("irgen_huuliin_etgeedin_ner", "")
            setValue("geree_gerchilgee_dugaar", "")
            setValue("full_name", "")
            setValue("mkv", "")
            setGerchilgees([])
            setChoosedNew(false)
        }

        if(value === 3)
        {
            setLabelType(NSDI_CADASTER)
        }
        else
        {
            setLabelType(NSDI_2D_BUILDING)
        }

    }

    useEffect(
        () =>
        {
            if(inputValues === '') return
            let zadalsanData = {
                irgen_huuliin_etgeedin_ner: inputValues.hariltsagch_info.irgen_huuliin_etgeedin_ner,
                register: inputValues.hariltsagch_info.register,

                project: inputValues.building_info?.building?.project,
                apartment: inputValues.building_info?.building?.apartment,
                building: inputValues.building_info?.building?.id,

                uil_ajillagaanii_turul: parseInt(inputValues.uil_ajillagaanii_turul),
                ul_hudluh_hurungiin_turul: inputValues.ul_hudluh_hurungiin_turul,
                mkv: inputValues.mkv,
                ognoo: new Date(inputValues.geree_info.ognoo),
                geree_gerchilgee_dugaar: inputValues.geree_info.geree_gerchilgee_dugaar,
                shimtgel_dun: inputValues.geree_info.shimtgel_dun,
                dugaar: inputValues.geree_info.dugaar,
                huwi: inputValues.geree_info.huwi,
                per_month_price: inputValues.per_month_price,
                gereenii_hugatsaa: inputValues.gereenii_hugatsaa,
                total_price: inputValues.total_price,
                unit1: inputValues.bairshil_info.aimar_hot_info?.id,
                unit2: inputValues.bairshil_info.sum_duureg_info?.id,
                unit3: inputValues.bairshil_info.bag_horoo_info?.id,
                full_name: inputValues.building_info?.full_name,
                geoid: inputValues.building_info?.geo_id,
                point: inputValues.building_info?.point_coordinates
            }
            setProject(inputValues.building_info?.building?.project)
            setApartment(inputValues.building_info?.building?.apartment)
            setUnit1(inputValues.bairshil_info.aimar_hot_info?.id)
            setUnit2(inputValues.bairshil_info.sum_duureg_info?.id)
            setRealValues(zadalsanData)
            // handleIsNew(inputValues.ul_hudluh_hurungiin_turul)
        },
        [inputValues]
    )

    const apartments = useMemo(
        () =>
        {
            if(!project) return null
            let unit1Id = typeof project === "object" ? project.id : project
            const unit = projectNested?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            setApartmentNested(unit?.apartments)
            return dataToValueLabel(unit?.apartments, 'id', 'name')
        },
        [project, projectNested]
    )

    const buildings = useMemo(
        () =>
        {
            if(!apartment) return null
            let unit1Id = typeof apartment === "object" ? apartment.id : apartment
            const unit = apartmentNested?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            return dataToValueLabel(unit?.buildings, 'id', 'name')
        },
        [apartment, apartmentNested]
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData, { reset }) =>
    {
        const point = choosedFeature.isFromMap
                ?   transform(getCenter(choosedFeature?.feature.getGeometry().getExtent()), FEATURE_PROJECTION, DATA_PROJECTION)
                :   formData.point
                    ? transform(formData.point, FEATURE_PROJECTION, DATA_PROJECTION)
                    : null

        let form_shimtgel_dun = (formData.price / 100) * formData.huwi

        const body = {
            uil_ajillagaanii_turul: formData.uil_ajillagaanii_turul,
            ul_hudluh_hurungiin_turul: formData.ul_hudluh_hurungiin_turul,
            mkv: formData.mkv,
            hariltsagch: {
                irgen_huuliin_etgeedin_ner: formData.irgen_huuliin_etgeedin_ner,
                register: formData.register,
            },
            geree: {
                ognoo: formData.ognoo,
                dugaar: formData.dugaar,
                geree_gerchilgee_dugaar: formData.geree_gerchilgee_dugaar,
                shimtgel_dun: form_shimtgel_dun,
                huwi: formData.huwi,
            },
            barilga: {
                building: formData.building,
                apartment: isNew ? choosedFeature?.feature?.get("apartment_id") : apartment,
                point: point,
            },
            bairshil: {
                aimag_hot: "",
                sum_duureg: "",
                bag_horoo: "",
            },
            per_month_price: formData.per_month_price,
            gereenii_hugatsaa: formData.gereenii_hugatsaa,
            total_price: formData.total_price,
        }

        const oldBody = {
            uil_ajillagaanii_turul: formData.uil_ajillagaanii_turul,
            ul_hudluh_hurungiin_turul: formData.ul_hudluh_hurungiin_turul,
            mkv: formData.mkv,
            hariltsagch: {
                irgen_huuliin_etgeedin_ner: formData.irgen_huuliin_etgeedin_ner,
                register: formData.register,
            },
            geree: {
                ognoo: formData.ognoo,
                dugaar: formData.dugaar,
                geree_gerchilgee_dugaar: formData.geree_gerchilgee_dugaar,
                shimtgel_dun: form_shimtgel_dun,
                huwi: formData.huwi,
            },
            bairshil: {
                aimag_hot: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit1 : formData.unit1,
                sum_duureg: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit2 : formData.unit2,
                bag_horoo: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit3 : formData.unit3,
            },
            barilga: {
                full_name: formData.full_name,
                geo_id: choosedFeature.isFromMap ? choosedFeature?.feature?.getId() : formData.geoid,
                point: point,
            },
            per_month_price: formData.per_month_price,
            gereenii_hugatsaa: formData.gereenii_hugatsaa,
            total_price: formData.total_price,
        }

        if(!typeof isNew === 'boolean')
        {
            delete body.barilga.geo_id
            delete oldBody.barilga.geo_id
        }

        if (isCreate)
        {
            const { errors, success, data } = await fetchData(buildingTableApi['turees'].post(choosedNew ? body : oldBody)).catch(error => error)
            if(success)
            {
                setTogglerState && setTogglerState(false)
                reset()
                typeof isNew !== 'boolean' && navigate(-1)
            }
            if (!success) return errors
            return
        }
        const { errors, success, data } = await fetchData(buildingTableApi['turees'].put(choosedNew ? body : oldBody, id)).catch(error => error)
        if(success)
            {
                setTogglerState && setTogglerState(false)
                reset()
                typeof isNew !== 'boolean' && navigate(-1)
                return
            }
        if (!success) return errors
    }

    const secondUnits = useMemo(
        () =>
        {
            if(!selectUnit1) return null
            let unit1Id = typeof selectUnit1 === "object" ? selectUnit1.id : selectUnit1
            const unit = units?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            setUnit2Options(unit?.unit2)
            return dataToValueLabel(unit?.unit2, 'id', 'name')
        },
        [selectUnit1, units]
    )

    const thirdUnits = useMemo(
        () =>
        {
            if(!selectUnit2) return []
            let unit2Id = typeof selectUnit2 === "object" ? selectUnit2.id : selectUnit2
            const unit = unit2Options?.find(
                (el) =>
                {
                    return el.id === unit2Id
                }
            )
            return dataToValueLabel(unit?.unit3, 'id', 'name', null, 'code')
        },
        [unit2Options, selectUnit2]
    )

    const displaySomeInputs = useMemo(
        () =>
        {
            if (isNew == true)
            {
                return [
                    {
                        inputType: "select",
                        registerName: "building",
                        options: buildingsOfApart,
                        colMd: 6,
                        disabled: !isCreate,
                    },
                ]
            }
            if(typeof choosedNew !== 'boolean') return []
            if(typeof isNew === 'boolean' && !isNew) return (
                [
                    {
                        inputType: "input",
                        registerName: "full_name",
                        type: "textArea",
                        colMd: 12
                    },
                ]
            )
            if(!choosedNew) return [
                {
                    inputType: "select",
                    registerName: "unit1",
                    onChange: value => setUnit1(value.value),
                    disabled: isLoading,
                    options: dataToValueLabel(units, 'id', 'name'),
                    disabled: !isCreate,
                    colMd: 4
                },
                {
                    inputType: "select",
                    registerName: "unit2",
                    onChange: value => setUnit2(value.value),
                    disabled: isLoading,
                    nested: 'unit1',
                    options: secondUnits,
                    disabled: !isCreate,
                    colMd: 4
                },
                {
                    inputType: "select",
                    registerName: "unit3",
                    disabled: isLoading,
                    nested: 'unit1',
                    sort: true,
                    options: thirdUnits,
                    disabled: !isCreate,
                    colMd: 4
                },
                {
                    inputType: "input",
                    registerName: "full_name",
                    type: "textArea",
                    colMd: 12
                },
                {
                    component: <MapChoice units={thirdUnits} disabled={!isCreate} labelType={ labelType }/>
                }
            ]
            return [
                {
                    inputType: "select",
                    registerName: "project",
                    onChange: value => setProject(value.value),
                    options: dataToValueLabel(projectNested, 'id', 'name'),
                    colMd: 12,
                    disabled: !isCreate,
                },
                {
                    inputType: "select",
                    registerName: "apartment",
                    onChange: value => setApartment(value.value),
                    nested: 'project',
                    options: apartments,
                    colMd: 6,
                    disabled: !isCreate,
                },
                {
                    inputType: "select",
                    registerName: "building",
                    nested: 'apartment',
                    options: buildings,
                    colMd: 6,
                    disabled: !isCreate,
                },
            ]
        },
        [choosedNew, projectNested, apartments, buildings, selectUnit1, units, unit2Options, selectUnit2, isNew, buildingsOfApart, apartment, project, thirdUnits, labelType]
    )

    const displayEstateTypes = useMemo(
        () =>
        {
            if (typeof isNew === "boolean") {
				return {
					inputType: "select",
					registerName: "ul_hudluh_hurungiin_turul",
					onChange: handleIsNew,
                    shouldReset: isNew,
                    disabled: !isCreate,
					options: estateTypes,
				}
			}
            else
            {
				return {
					inputType: "select",
					registerName: "ul_hudluh_hurungiin_turul",
					onChange: handleIsNew,
                    disabled: !isCreate,
					options: UL_HODLOH_TURUL_TUREES,
				}
			}
        },
        [isNew, estateTypes, UL_HODLOH_TURUL_TUREES]
    )

    const gerchilgeesMemo = useMemo(
        () =>
        {
            return gerchilgees.map(
                (item, idx) =>
                {
                    return {
                        label: item['propertyNationRegisterNumber'],
                        value: item['propertyNationRegisterNumber'],
                    }
                }
            )
        },
        [gerchilgees]
    )

    /** Form утгууд */
    const form =
    {
        inputs: [
            displayEstateTypes,
            {
                component: (
                    <hr />
                )
            },
            {
                inputType: "input",
                registerName: "register",
                type: "text",
                onChange: getUserData,
            },
            {
                inputType: "input",
                registerName: "irgen_huuliin_etgeedin_ner",
                type: "text",
            },
            {
                component: (
                    <hr />
                )
            },
            choosedNew
            ?
                {
                    inputType: "input",
                    registerName: "geree_gerchilgee_dugaar",
                    type: "text",
                    disabled: !isCreate,
                }
            :
                // {
                //     inputType: "select",
                //     registerName: "geree_gerchilgee_dugaar",
                //     options: gerchilgeesMemo,
                //     onChange: handleChangeGerchilgees,
                //     disabled: !isCreate,
                // },
                {
                    inputType: "input",
                    registerName: "geree_gerchilgee_dugaar",
                    type: "text",
                    // onChange: event => handleChangeGerchilgeesV2(event.target.value),
                    onChange: handleChangeGerchilgeesV2,
                    label: {
                        empty: true,
                    },
                    disabled: !isCreate,
                },
            {
                inputType: "input",
                registerName: "dugaar",
                type: "text",
            },
            {
                component: (
                    <hr />
                )
            },
            ...displaySomeInputs,
            isNew === true || !choosedNew
            &&
                {
                    inputType: "input",
                    registerName: "mkv",
                    type: "number",
                    // ???????
                    // disabled: true,
                    step: 0.01,
                },
            {
                inputType: 'select',
                registerName: 'uil_ajillagaanii_turul',
                sort: true,
                options: activitys,
            },
            {
                component: (
                    <hr />
                )
            },
            {
                inputType: "input",
                registerName: "per_month_price",
                type: "number",
            },
            {
                inputType: "input",
                registerName: "gereenii_hugatsaa",
                type: "number",
            },
            {
                inputType: "input",
                registerName: "description",
                type: "textarea",
            },
            {
                inputType: "input",
                registerName: "total_price",
                type: "number",
            },
            {
                inputType: "input",
                registerName: "zurag",
                type: "file",
            },
            {
                component: (
                    <hr />
                )
            },
            {
                inputType: "datePicker",
                registerName: "ognoo",
            },
            // {
            //     inputType: "input",
            //     registerName: "shimtgel_dun",
            //     type: "number",
            //     step: 0.01,
            // },
            {
                inputType: "input",
                registerName: "huwi",
                type: "number",
                step: 0.01,
            },
        ],
        button: {
            onSubmit: btnOnSubmit,
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "onChange",
            yupValidate: (
                typeof isNew === 'boolean'
                ?
                    isNew
                    ?
                        tureesValidate2
                    :
                        OldTureesValidate2
                :
                    choosedNew
                    ?
                        tureesValidate
                    :
                        OldTureesValidate
            ),
        },
    }
    return (
        <BoxBackground title={hasTitle ? 'Зуучлалын мэдээ' : ''} body={
            <>
                {isLoading && Loader}
                <div className="text-center mb-2 mt-2 fs-15" style={{ fontWeight: "500", maxWidth: "600px", margin: "0 auto" }}>
                    <span>ТҮРЭЭСЛЭХ</span>
                </div>
                <CForm isLoading={isLoading} inputValues={realValues} form={form}/>
            </>
        } />
    );
}

export default TureesAction;
