import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'

import useLoader from 'hooks/useLoader'

import Table from 'components/main/Table'
import CPagination from './CPagination'

import { ASC, DESC, SequenceColName } from 'components/main/Table/Thead'
import SearchInput from './SearchInput'
import useCState from 'hooks/useCState'
import { useLocation } from 'react-router-dom'

/**
 * Table доторх датануудыг хуудаслаж хайлт болон хуудас солих зэргийг хийх нь
 * @param {Function} onPageChange       хуудас солигдох болгонд ажиллах
 * @param {Function} onOrderChange      header дээр order солигдох болгонд ажиллах
 * @param {Function} onPerPageChange    Нэг хуудсанд харагдах датаны тоо солигдох болгонд ажиллах
 *
 * @param {Function} request.axios      Хүсэлт явуулах axios
 * @param {Function} request.params     axios руу дамжуулах parametr үүд
 * @param {Function} request.then       хүсэлт ирсэний дараа ажиллах функц
 * @param {Function} request.catch      хүсэлт дээр алдаа гарвал ажиллах функц
 * @param {Function} request.finally    хүсэлт дууссаны дараа ажиллах функц
 *
 * @param {Object}   paginationConfig.containerPerPage
 * @param {Object}   paginationConfig.containerJumpPage
 * @param {Object}   paginationConfig.containerPagination
 * @param {Object}   paginationConfig.containerPages
 *
 * @param {Function} dRef               Датаtable тай холбоотой зүйлс
 * @param {boolean}  isFirstGetData     component дотроос дата дуудах эсэх
 *
 * @param {Array} extraHeaderElements Datatable ийн толгой дээр харуулах товч
 */
export default function Datatable({
    columns,
    rows=[],
    sequenceColumn,
    tableOptions,
    extraColumns,
    extraRows,
    className,
    tableId,
    downloadAble,
    excelInfo,
    colSpanHeader,
    hasSearch=true,

    initPage=1,
    perPage=20,
    maxPage=1,

    onPageChange,
    onOrderChange,
    onPerPageChange,
    getConfigs,
    request,
    paginationConfig={},
    dRef,
    isFirstGetData=true,
    checkBoxOptions,

    extraHeaderElements=[],
    printHeader
})
{

    const mainOption = {
        tableOuterClassName: `dtable-height`,
        className: className,
        striped: true,
    }

    /** table ийн мөрийн утгууд */
    const [tableRows, setRows] = useState(rows)
    const location = useLocation()
    const { Loader, fetchData, isLoading, cancel } = useLoader({ absolute: true, source: request?.source })
    const { checkedList, setCheckedList, checkedListInit } = useCState()
    /** datatble дээр солигдсон утгуудыг хадгалах state */
    const [ config, setConfig ] = useState(
        {
            maxPage: maxPage,
            initPage: initPage,
            sort: {
                field: tableOptions?.defaultSort?.field || SequenceColName,
                type: tableOptions?.defaultSort?.type || ASC,
            },
            match: {},
            perPage: perPage,
            page: initPage,
            startIndex: sequenceColumn.startIndex,
            search: {},
            total: 0
        }
    )
    /** Гаднаас row ийн утга солигдох үед дотоод state дээ хадгалж харуулах нь */
    useEffect(
        () =>
        {
            /** Гаднаас утгаа авдаг бол ажиллана */
            if (request.then)
            {
                setRows(rows)
            }
        },
        [rows]
    )

    useEffect(
        () =>
        {
            if ("socket" in request)
            {
                request.socket.on(
                    (data) =>
                    {
                        setConfig((prev) => ({ ...prev, maxPage: data.maxPage, page: data.page, startIndex: data.startIndex, maxPage: data.limit }))
                        setRows(data.data)
                    }
                )
            }

            return () =>
            {
                if ("socket" in request)
                {
                    request.socket.off()
                }
            }
        },
        []
    )

    useLayoutEffect(
        () =>
        {
            if (dRef)
                dRef.current = dTableRef.current
        },
        []
    )

    /** config ийн утга солигдох үед тохиргоог буцаах
     * @param {object} newConfig өөрчлөгдсөн datatable ийн тохиргоо
     * @param {Function} callback хүсэлт ирсэний дараа ажиллах функц
     * @param {newParams} newParams тухайн хүсэлт явуулж буй функц руу дамжуулах шинэ параметрүүд
    */
    const getData = (newConfig, callback, newParams, newAxios) =>
    {
        if (isLoading) return
        /** axios руу дамжуулах parametr үүд */
        const params = [
            ...newParams
                ? newParams
                : request.params,
            ...Object.keys(newConfig).length > 0
                ? [
                    newConfig.page,
                    newConfig.perPage,
                    newConfig.sort,
                    newConfig.match,
                    newConfig.search
                ]
                : [
                    config.page,
                    config.perPage,
                    config.sort,
                    config.match,
                    config.search
                ]
        ]

        /** хүснэгтийн утгийг авах хүсэлт явуулж байна */
        fetchData((newAxios ? newAxios : request.axios)(...params))
            .then((rsp) =>
            {
                const { success, data, error } = rsp
                if (success)
                {
                    setRows(data.data)
                    /** шинэ утга авч байгаа config */
                    const clonedConfig = { ...config, ...newConfig, maxPage: data.maxPage, startIndex: data.startIndex, page: data.page, total: data.total }
                    setCheckedList(prev => {
                        return { ...prev, total: data.total }
                    })
                    setConfig(clonedConfig)
                    if (callback)
                        callback(clonedConfig)

                    if (getConfigs)
                        getConfigs(clonedConfig)
                }

                /** then гэсэн функц байх юм бол гадна байгаа state ийг ашиглана */
                if (request.then)
                {
                    request.then(data)
                }
            })
            .catch(err =>
                {
                    /** хэрвээ catch гэсэн фукнц байх юм бол ажиллуулана  */
                    if (request.catch)
                    {
                        request.catch(err)
                    }

                    /** Алдаа гарсан байвал хүснэгтийг хоослоно  */
                    setRows([])

                }
            )
            .finally(
                () =>
                {
                    if (request.finally)
                    {
                        request.finally()
                    }
                }
            )
    }

    /** Хүснэгтийн header ийг sort дарахад ямар header ийг ямар төрлөөр sort хийхийг авах функц
     * @param {string} field    хүснэгтийн баганы нэр
     * @param {("ASC"|"DESC")} sort     эрэмбэлэсэн төрөл
     */
    const handleOnOrderChange = ({ field, type }, extraFilter) =>
    {
        if (onOrderChange)
            onOrderChange({ field, type })

        const clonedConfig = { ...config, sort: { field, type }, ...extraFilter && Object.keys(extraFilter).length > 0 ? extraFilter : {} }
        /** Хамгийн анх ачааллахад sort хийснээр ажилладаг тул нөхцөл шалгах */
        if (dRef && dRef.current.firstGet === true)
            getData(clonedConfig)
        else if (!dRef)
            getData(clonedConfig)
    }

    /** Хуудсын тоо солигдоход ажиллах функц */
    const handleChangePage = (page, callback) =>
    {
        if (onPageChange)
            onPageChange(page)

        const clonedConfig = { ...config, page }
        getData(clonedConfig, callback)
    }

    /** Нэг хуудсанд харагдах датаны тоо тохируулвал state -д хадгалах нь */
    const handleChangePerpage = (perPage, callback) =>
    {
        if (onPerPageChange)
            onPerPageChange(perPage)

        const clonedConfig = { ...config, perPage }
        getData(clonedConfig, callback)
    }

    /** датаtable тай холбоотой ref үүд */
    const dTableRef = useRef(
        {
            /** component дотроос дата дуудах эсэх */
            firstGet: isFirstGetData,
            cancel: cancel,
        }
    )

    /** датаг дахин ачааллууллах */
    dTableRef.current.reData = (...args) => getData(...args)

    const handleFilterChange = (filters) =>
    {
        const clonedConfig = { ...config, match: filters }
        getData(clonedConfig)
    }

    //
    const searchFields = columns.filter(val => (val?.noSearch == false || val?.noSearch == undefined))

    const searchCallback = text =>
    {
        let search = {}
        searchFields.map(
            (element) =>
            {
                if(typeof element.searchField === 'boolean' && !element.searchField) return
                Object.assign(search, { ["cs_" + (element.searchField ? element.searchField : element.field)]: text })
            }
        )
        const clonedConfig = { ...config, search }
        getData(clonedConfig)
    }

    const onCheckAll = (event) =>
    {
        checkBoxOptions.onCheckAll && checkBoxOptions.onCheckAll(event.target.checked, 'all')
        setCheckedList(prev => {
            return {
                ...prev,
                isAll: event.target.checked,
                unChecked: [],
                checked: [],
            }
        })
    }

    const onCheck = (event, row) =>
    {
        checkBoxOptions.onCheckAll && checkBoxOptions.onCheck(event.target.checked, row)
        if (checkedList.isAll && !event.target.checked)
        {
            setCheckedList(prev => {
				return {
                    ...prev,
                    unChecked: [ ...prev.unChecked, row[checkBoxOptions.rowsElement] ],
                }
			})
            return
        }
        if (checkedList.isAll && event.target.checked)
        {
            setCheckedList(prev => {
				return {
                    ...prev,
                    unChecked: prev.unChecked.filter( e => e !== row[checkBoxOptions.rowsElement]),
                }
			})
            return
        }

        if(event.target.checked)
        {
            setCheckedList(prev => {
				return {
                    ...prev,
                    checked: [ ...prev.checked, row[checkBoxOptions.rowsElement] ],
                }
			})
            return
        }

        if(!event.target.checked)
        {
            setCheckedList(prev => {
				return {
                    ...prev,
                    checked: prev.checked.filter(item => item !== row[checkBoxOptions.rowsElement]),
                }
			})
            return
        }
    }

    useEffect(
        () =>
        {
            if(checkedList.checked.length !== 0)
            {
                setCheckedList(checkedListInit)
                return
            }
            if(checkedList.isAll)
            {
                setCheckedList(checkedListInit)
                return
            }
        },
        [location.pathname]
    )

    return (
        <div className='position-relative ari'>
            {hasSearch && <SearchInput searchCallback={searchCallback} />}
            <Table
                printHeader={printHeader}
                columns={columns}
                rows={tableRows}
                tableId={tableId}
                onCheckAll={onCheckAll}
                onCheck={onCheck}
                handleFilterChangeDataTable={handleFilterChange}
                downloadAble={downloadAble}
                excelInfo={excelInfo}
                colSpanHeader={colSpanHeader}
                sequenceColumn={{ ...sequenceColumn, startNumber: config.startIndex }}
                options={{
                    ...mainOption,
                    ...tableOptions
                }}
                extraColumns={extraColumns}
                checkBoxOptions={checkBoxOptions}
                extraRows={extraRows}
                extraHeaderElements={extraHeaderElements}
                isLoading={isLoading}
                onOrderChange={handleOnOrderChange}
            />
            <CPagination
                isLoading={isLoading}
                page={config.initPage}
                checkedList={checkedList}
                checkboxButton={checkBoxOptions}
                perPage={config.perPage}
                maxPage={config.maxPage}
                onPageChange={handleChangePage}
                onPerPageChange={handleChangePerpage}
                paginationConfig={paginationConfig}
            />
        </div>
    )
}
